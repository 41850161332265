import { Link } from 'gatsby';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { localizedUrl } from '../utils/js/locale-utils';
import ArchivePhotos from './images/archive-photos';
import CadeauNoel from './images/cadeau-noel';
import ConfitureAbricots from './images/confiture-abricot';
import PhysiqueChimie from './images/physique-chimie';
import SauceTomateMaison from './images/sauce-tomate-maison';
import VinDeNoix from './images/vin-de-noix';
import categoryCardsStyles from './category-cards.module.scss';

const CategoryCards = () => {
  const categories = [
    {
      code: 'jam',
      image: <ConfitureAbricots />,
    },
    {
      code: 'preserve',
      image: <SauceTomateMaison />,
    },
    {
      code: 'school',
      image: <PhysiqueChimie />,
    },
    {
      code: 'bottle',
      image: <VinDeNoix />,
    },
    {
      code: 'gift',
      image: <CadeauNoel />,
    },
    {
      code: 'other',
      image: <ArchivePhotos />,
    },
  ];

  const categoryCards = [];
  categories.forEach((category) => {
    categoryCards.push(
      <div className={`${categoryCardsStyles.categoryCard} column is-one-third has-text-centered`} key={category.code}>
        <div className="card">
          <div className="card-content">
            <Link
              to={localizedUrl(category.code === 'other' ? '/create/shape' : `/${category.code}/create/shape`)}
              className="has-text-primary-light"
            >
              <figure className="image">
                {category.image}
              </figure>

              <h3 className="title is-6 m-t-md">
                <FormattedMessage id={`category-cards.${category.code}`} />
              </h3>
            </Link>
          </div>
        </div>
      </div>,
    );
  });

  return (
    <div className="columns is-multiline">
      {categoryCards}
    </div>
  );
};

CategoryCards.propTypes = {
};

CategoryCards.defaultProps = {
};

export default injectIntl(CategoryCards);
