import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import CategoryCards from '../components/category-cards';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = ({ intl }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: '404.title' })} />
    <section className="container section">
      <h1 className="title is-4">
        <FormattedMessage id="404.title" />
      </h1>
      <h2>
        <FormattedMessage id="404.not-found" />
      </h2>
    </section>
    <section className="hero is-light">
      <div className="hero-body section container">
        <p className="m-b-sm">
          <FormattedMessage id="404.not-found2" />
        </p>
        <div className="columns">
          <div className="column is-6-mobile is-offset-3-mobile is-12-desktop is-8-widescreen is-offset-2-widescreen">
            <CategoryCards />
          </div>
        </div>
      </div>
    </section>

  </Layout>
);

NotFoundPage.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(NotFoundPage);
